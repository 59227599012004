import { environment } from '@accredible-frontend-v2/envs';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { AccredishareRequest } from './accredishare.model';
import { AccredishareTrackResponse } from './accredishare.model';
import { AccredishareGenerateHashtagResponse } from './accredishare.model';
import { Observable } from 'rxjs';
import { RecipientPortalApiService } from '../rp-api/rp-api.service';

@Injectable({
  providedIn: 'root',
})
export class AccredishareApiService extends RecipientPortalApiService {
  private readonly API_TRACK_URL = '/track';
  private readonly API_GENERATE_HASHTAG_URL = '/generate';

  constructor(@Inject(DOCUMENT) protected readonly _document: Document) {
    super(_document);

    this.apiUrl = environment.accredishareUrl;
  }

  track(request: AccredishareRequest): Observable<AccredishareTrackResponse> {
    return this._post(this.API_TRACK_URL, request).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  generateHashtag(uuid: string): Observable<AccredishareGenerateHashtagResponse> {
    return this._post(this.API_GENERATE_HASHTAG_URL, { credential_uuid: uuid }).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }
}
