import { AccredibleSocialMedia } from './social-media.model';

export interface AccredibleIssuer {
  id: number;
  name: string;
  url: string;
  description: string;
  social_media: AccredibleSocialMedia;
  support_contact_email: string;
  show_external_recommendations: boolean;
  logo?: string;
  use_support_ticket_url: boolean;
  use_knowledgebase_url: boolean;
  support_ticket_url: string;
  knowledgebase_url: string;
}

export interface AccredibleCredentialIssuer extends AccredibleIssuer {
  image_url: string;
  allow_supplemental_references: boolean;
  allow_supplemental_evidence: boolean;
  created_at: number;
  language: string;
  customer: boolean;
  whitelisted_domain: string;
  twitter_handle: string;
  certificate_expiry_message: string;
  recipient_functions: RecipientFunctions;
  name_changes_auto_approve: string;
  digital_wallet_passes: boolean;
  show_group_recommendations_on_credential_view: boolean;
  pathways_enabled: boolean;
}

export interface AccredibleOrganizationIssuer extends AccredibleIssuer {
  image_address: string;
  page_banner: string;
  recipient_directory: boolean;
  verified: boolean;
  support_contact_email: string;
  spotlight_directory_url: string;
  verification_directory_url: string;
  groups_count: number;
  course_sort_order: string;
  collections_count: number;
  pathways_enabled: boolean;
  published_pathway_count?: number;
  published_pathway_sequences_count?: number;
  website?: string;
  coursefinder_enabled?: boolean;
}

interface RecipientFunctions {
  // API Keys
  sharing: RecipientFunctionPrivacy;
  download_pdf: RecipientFunctionPrivacy;
  download_badge: RecipientFunctionPrivacy;
  email_share: RecipientFunctionPrivacy;
  // embed: RecipientFunctionPrivacy;
  change_privacy: RecipientFunctionPrivacy;
  change_name: RecipientFunctionPrivacy;
  transcript_viewable: RecipientFunctionPrivacy;
  request_reference: RecipientFunctionPrivacy;
  download_pdf_print: RecipientFunctionPrivacy;
  add_to_backpack: RecipientFunctionPrivacy;
  add_to_linkedin: RecipientFunctionPrivacy;
  share_to_linkedin: RecipientFunctionPrivacy;
  add_evidence: RecipientFunctionPrivacy;
  refer_a_friend: RecipientFunctionPrivacy;
  ask_recovery_email: boolean;
  // OUR Keys
  share: RecipientFunctionPrivacy;
  pdf: RecipientFunctionPrivacy;
  badge: RecipientFunctionPrivacy;
  email: RecipientFunctionPrivacy;
  embed: RecipientFunctionPrivacy;
  privacy: RecipientFunctionPrivacy;
  requestNameChange: RecipientFunctionPrivacy;
  transcriptLetter: RecipientFunctionPrivacy;
  printPdf: RecipientFunctionPrivacy;
  addToLinkedIn: RecipientFunctionPrivacy;
  shareToLinkedIn: RecipientFunctionPrivacy;
  addEvidence: RecipientFunctionPrivacy;
  referAFriend: RecipientFunctionPrivacy;

  [key: string]: RecipientFunctionPrivacy | boolean;
}

// FYI:
// 'disabled' = hidden for everyone
// 'private' = hidden for everyone except the owner
type RecipientFunctionPrivacy = 'public' | 'private' | 'disabled';
