import { ActiveFeatureFlags } from '@accredible-frontend-v2/models';
import { AccredibleRecipientFeatureFlagsService } from '@accredible-frontend-v2/services/recipient-feature-flags';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

export const transcriptLetterGuard: CanActivateFn = (route) => {
  const featureFlagService = inject(AccredibleRecipientFeatureFlagsService);
  const router = inject(Router);

  return featureFlagService.getFeatureFlags().pipe(
    map((flags) => {
      const targetUrl = route.url.join('/');
      const isTranscriptRoute = targetUrl.includes('transcript-letter');
      const isCredentialRoute = targetUrl.includes('credential-letter');

      // Handle route redirection based on feature flag
      if (flags[ActiveFeatureFlags.V2_TRANSCRIPTS] && isTranscriptRoute) {
        return router.createUrlTree([targetUrl.replace('transcript-letter', 'credential-letter')]);
      }

      if (!flags[ActiveFeatureFlags.V2_TRANSCRIPTS] && isCredentialRoute) {
        return router.createUrlTree([targetUrl.replace('credential-letter', 'transcript-letter')]);
      }

      return true;
    }),
  );
};
