import { DOCUMENT } from '@angular/common';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccredishareService } from './accredishare.service';

/**
 * Service to track clicks on GetSocial buttons.
 *
 * This service is intended to work alongside the GetSocial widget to track button clicks and call our API.
 * Once the dedicated widget is built, this service can be removed and the current implementation can be used.
 */
@Injectable({
  providedIn: 'root',
})
export class AccredishareClickTrackingService implements OnDestroy {
  private _accredishareService = inject(AccredishareService);
  private _document = inject(DOCUMENT);

  // GetSocial supports more networks, but we are focusing only on these specific ones.
  readonly PROVIDERS_SUPPORTED = new Map([
    ['linkedin', 'linkedin'],
    ['twitter', 'x'],
    ['facebook', 'facebook'],
    ['whatsapp', 'whatsapp'],
    ['fbm', 'messenger'],
    ['telegram', 'telegram'],
  ]);

  private _credentialUuid: string;
  private _handleEventDelegation: (event: Event) => void;

  /**
   * Initializes tracking for GetSocial buttons by setting up an event listener for click events.
   */
  initTrackingGetSocial(credentialUuid: string): void {
    this._credentialUuid = credentialUuid;

    this._handleEventDelegation = (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.closest('a')) {
        this._handleButtonClick(event);
      }
    };

    // Select elements related to GetSocial that we want to track
    // This includes the widget and also the dialog that opens up
    const relevantElements = this._document.querySelectorAll('.acc-get-social, .gs-popup-body');

    relevantElements.forEach((element) => {
      element.addEventListener('click', this._handleEventDelegation, true);
    });
  }

  //TODO(LuísF): accredishare_tracking - delete this once GetSocial is no longer in use
  trackGSVisit(): Observable<boolean> {
    const gsTracker = new BehaviorSubject(false);
    // Override XMLHttpRequest open method
    const originalXHR = XMLHttpRequest.prototype.open;

    XMLHttpRequest.prototype.open = function (method, url) {
      if (url.toString().includes('https://api.at.getsocial.io/api/v1/elements/status')) {
        gsTracker.next(true);
        console.log('calling accredishare tracking service for page views');
      }

      // Call the original open method
      // eslint-disable-next-line prefer-rest-params
      originalXHR.apply(this, arguments);
    };
    return gsTracker;
  }

  /**
   * Handles the button click event by determining if it is a GetSocial button and tracking the share.
   **/
  private _handleButtonClick(event: Event) {
    let element = event.target as HTMLElement;

    // Traverse up the DOM tree to find an element with the 'gs-tracking' class
    while (element && !element.classList.contains('gs-tracking')) {
      element = element.parentElement;
    }

    if (!element) {
      return;
    }

    const action = element.getAttribute('data-action');
    const provider = element.getAttribute('data-provider');
    const providerValue = this.PROVIDERS_SUPPORTED.get(provider);

    if ((action === 'share' || action === 'tweet') && providerValue) {
      this.trackShare(this._credentialUuid, providerValue);
    }
  }

  /**
   * Calls the AccredishareService to track the share action.
   */
  private trackShare(credentialUuid: string, provider: string) {
    this._accredishareService.trackShare(credentialUuid, provider).subscribe({
      error: (err) => console.error('Tracking error:', err),
    });
  }

  /**
   * Cleanup event listeners when the service is destroyed.
   */
  ngOnDestroy(): void {
    const relevantElements = this._document.querySelectorAll('.acc-get-social, .gs-popup-body');
    relevantElements.forEach((element) => {
      element.removeEventListener('click', this._handleEventDelegation, true);
    });
  }
}
