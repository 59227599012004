export interface AccredishareRequest {
  event_type: EventType;
  data: CommonAccredishareShareData & Partial<VisitData & ReferralData & ShareData>;
}

export interface AccredishareTrackResponse {
  session_id?: string;
}

export interface AccredishareGenerateHashtagResponse {
  hashtag: string;
}

export interface CommonAccredishareShareData {
  credential_url: string;
  session_id?: string;
  platform: string;
}

export enum EventType {
  SHARE = 'SHARE',
  VISIT = 'VISIT',
  REFERRAL = 'REFERRAL',
}

export enum PlatformType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export interface VisitData {
  credential_uuid: string;
}

export interface ReferralData {
  utm_source?: string;
  utm_medium?: string;
  hashtag?: string;
  credential_uuid: string;
}

export interface ShareData {
  credential_uuid: string;
  utm_source: string;
}

export interface LinkData {
  credentialId: string;

  [key: string]: string;
}

export type ShareType = 'wallet' | 'credential';
