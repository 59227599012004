import { FeatureFlags } from '@accredible-frontend-v2/models';
import { AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccredibleRecipientFeatureFlagsService extends AccredibleApiService {
  private readonly _featureFlags$ = new BehaviorSubject(null);
  featureFlags$ = this._featureFlags$.asObservable();

  featureFlags(): FeatureFlags {
    return this._featureFlags$.getValue();
  }

  getFeatureFlags(organizationId?: number): Observable<FeatureFlags> {
    if (this._featureFlags$.getValue()) {
      return this.featureFlags$;
    }

    return this._get(`/v1/recipient/features?organization_id=${organizationId}`).pipe(
      map((res) => this._handleResponse(res, 'features')),
      tap((res) => this._featureFlags$.next(res)),
      catchError((res) => this._handleError(res, true)),
    );
  }
}
