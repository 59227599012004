import { AccredibleDialogService } from '@accredible-frontend-v2/dialog';
import { environment } from '@accredible-frontend-v2/envs';
import { ActiveFeatureFlags } from '@accredible-frontend-v2/models';
import { AccredibleAccountsRedirectionService } from '@accredible-frontend-v2/services/accounts-redirection';
import { AccredibleAuthenticationApiService } from '@accredible-frontend-v2/services/authentication';
import { AccredibleBrowserStorageService } from '@accredible-frontend-v2/services/browser-storage';
import { AccredibleCookiesService } from '@accredible-frontend-v2/services/cookies';
import { AccredibleRecipientFeatureFlagsService } from '@accredible-frontend-v2/services/recipient-feature-flags';
import { AccredibleSeoService } from '@accredible-frontend-v2/services/seo';
import { AccredibleUserApiService } from '@accredible-frontend-v2/services/user';
import { accredibleCustomThemesMetadata } from '@accredible-frontend-v2/utils/themes';
import { WindowHelper } from '@accredible-frontend-v2/utils/window-helper';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  createNgModule,
  DestroyRef,
  ElementRef,
  inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { filter, map, skip, switchMap } from 'rxjs/operators';
import { AccredishareClickTrackingService } from '../shared/services/accredishare/accredishare-click-tracking.service';
import { AccredishareService } from '../shared/services/accredishare/accredishare.service';
import { ThemeHelper } from '../themes/theme.helper';
import {
  EnableMfaPromptDialogComponent,
  PromptDialogResult,
} from './components/enable-mfa-prompt-dialog/enable-mfa-prompt-dialog.component';

const DEFAULT_THEME_DOMAIN = 'default.recipient-portal.theme';
const ROUTE_CHANGE = 'routeChange';
const RECIPIENT_PORTAL = 'recipient-portal';
const DONT_SHOW_MFA_PROMPT = 'dont_show_mfa_prompt';

enum BrowserStorageKey {
  THEME_DOMAIN = 'themeDomain',
}

enum ComponentType {
  HEADER = 'header',
  FOOTER = 'footer',
}

const CUSTOM_COOKIE_BANNER_DOMAINS = [
  'certifications.mitel.com',
  'salescertifications.mitel.com',
  'gppcertifications.mitel.com',
];

export const ROUTES_WITHOUT_HEADER_FOOTER = [/\/embed\/profile/i, /\/profile\/\w+\/share/i];

@Component({
  selector: 'rp-root',
  templateUrl: './recipient-portal.container.html',
  styleUrls: [`./recipient-portal.container.scss`],
})
export class RecipientPortalContainer implements AfterViewInit, OnInit {
  private readonly _injector = inject(Injector);
  private readonly _router = inject(Router);
  private readonly _authenticationApi = inject(AccredibleAuthenticationApiService);
  private readonly _userApi = inject(AccredibleUserApiService);
  private readonly _browserStorage = inject(AccredibleBrowserStorageService);
  private readonly _seoService = inject(AccredibleSeoService);
  private readonly _recaptchaV3Service = inject(ReCaptchaV3Service);
  private readonly _accountsRedirection = inject(AccredibleAccountsRedirectionService);
  private readonly _cookies = inject(AccredibleCookiesService);
  private readonly _dialog = inject(AccredibleDialogService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _document = inject(DOCUMENT);
  private readonly _accredishareClickTrackingService = inject(AccredishareClickTrackingService);
  private readonly _accredishareService = inject(AccredishareService);
  private readonly _recipientFeatureFlags = inject(AccredibleRecipientFeatureFlagsService);
  private _theme = ThemeHelper.getTheme();

  @ViewChild('headerContainer', { read: ViewContainerRef })
  headerContainer: ViewContainerRef;

  @ViewChild('headerTag')
  headerTag: ElementRef<HTMLElement>;

  @ViewChild('footerContainer', { read: ViewContainerRef })
  footerContainer: ViewContainerRef;

  // This is only used inside TG iframe
  isInThemeGeneratorPreviewIframe = false;
  headerAndFooterVisible = false;
  // TODO(Joao) Workaround for Wharton until we implement the full solution
  hasCustomCookieBanner = false;
  readonly version = environment.version;

  ngOnInit(): void {
    // TODO(LuísF): accredishare_tracking - remove this once we stop using GetSocial
    this._recipientFeatureFlags
      .getFeatureFlags()
      .pipe(
        map((features) => features[ActiveFeatureFlags.ACCREDISHARE_TRACKING]),
        filter((tracking) => tracking),
        switchMap(() => this._accredishareClickTrackingService.trackGSVisit()),
        filter((tracking) => tracking),
        map(() => {
          const inWalletPage = window.location.href.includes('/wallet');
          const uuid = window.location.pathname.split('/').filter((value) => value !== '')[0];
          return { inWalletPage, uuid };
        }),
        switchMap((data) =>
          data.inWalletPage
            ? this._accredishareService.trackWallet()
            : this._accredishareService.trackCredential(data.uuid),
        ),
      )
      .subscribe();

    const hostName = window.location.hostname;
    this.hasCustomCookieBanner = CUSTOM_COOKIE_BANNER_DOMAINS.includes(hostName);

    this._seoService.generateTags();
    this.isInThemeGeneratorPreviewIframe = environment.theming && WindowHelper.isInIframe();
  }

  ngAfterViewInit(): void {
    if (environment.theming && this._browserStorage.get(BrowserStorageKey.THEME_DOMAIN)) {
      this._theme = ThemeHelper.getTheme(this._browserStorage.get(BrowserStorageKey.THEME_DOMAIN));
    }

    if (this.isInThemeGeneratorPreviewIframe && this._theme === DEFAULT_THEME_DOMAIN) {
      emitCustomHeaderFooterStatus({ customHeader: false, customFooter: false });
    }

    this._onNavigationEnd();
    this._setupOnLogout();
    this._openEnableMfaPromptDialog();
  }

  private async _loadHeaderComponent(): Promise<any> {
    // If this theme is a duplicate, reference the domain of the original theme in order to get the header component
    const themeDomain = accredibleCustomThemesMetadata[this._theme]?.duplicateOf || this._theme;

    // We use a try catch here so the app will attempt to load the header component for this._theme but if it does not exist, e.g. if it's not been built. We load the default header component instead.
    try {
      const { HeaderComponent } = await import(`../themes/${themeDomain}/header/header.component`);
      import(`../themes/${themeDomain}/header/header.component.module`)
        .then((m) => m.HeaderComponentModule)
        .then(async (module) => {
          await this._createComponent(ComponentType.HEADER, module, HeaderComponent);
          if (this.isInThemeGeneratorPreviewIframe && themeDomain !== DEFAULT_THEME_DOMAIN) {
            emitCustomHeaderFooterStatus({ customHeader: true });
          }
        });
    } catch {
      const { HeaderComponent } = await import(
        '../themes/default.recipient-portal.theme/header/header.component'
      );
      import('../themes/default.recipient-portal.theme/header/header.component.module')
        .then((m) => m.HeaderComponentModule)
        .then(async (module) => {
          await this._createComponent(ComponentType.HEADER, module, HeaderComponent);
          if (this.isInThemeGeneratorPreviewIframe) {
            emitCustomHeaderFooterStatus({ customHeader: false });
          }
        });
    }
  }

  private async _loadFooterComponent(): Promise<any> {
    // If this theme is a duplicate, reference the domain of the original theme in order to get the footer component
    const themeDomain = accredibleCustomThemesMetadata[this._theme]?.duplicateOf || this._theme;

    // We use a try catch here so the app will attempt to load the footer component for this._theme but if it does not exist, e.g. it's not been built. We load default footer component instead.
    try {
      const { FooterComponent } = await import(`../themes/${themeDomain}/footer/footer.component`);
      import(`../themes/${themeDomain}/footer/footer.component.module`)
        .then((m) => m.FooterComponentModule)
        .then(async (module: unknown) => {
          await this._createComponent(ComponentType.FOOTER, module, FooterComponent);
          if (this.isInThemeGeneratorPreviewIframe && themeDomain !== DEFAULT_THEME_DOMAIN) {
            emitCustomHeaderFooterStatus({ customFooter: true });
          }
        });
    } catch {
      const { FooterComponent } = await import(
        '../themes/default.recipient-portal.theme/footer/footer.component'
      );
      import('../themes/default.recipient-portal.theme/footer/footer.component.module')
        .then((m) => m.FooterComponentModule)
        .then(async (module) => {
          await this._createComponent(ComponentType.FOOTER, module, FooterComponent);
          if (this.isInThemeGeneratorPreviewIframe) {
            emitCustomHeaderFooterStatus({ customFooter: false });
          }
        });
    }
  }

  private async _createComponent(
    componentType: ComponentType,
    module: any,
    component: any,
  ): Promise<any> {
    const moduleRef = createNgModule(module, this._injector);

    switch (componentType) {
      case ComponentType.HEADER:
        this.headerContainer.clear();
        this.headerContainer.createComponent(component, { ngModuleRef: moduleRef });
        break;
      case ComponentType.FOOTER:
        this.footerContainer.clear();
        this.footerContainer.createComponent(component, { ngModuleRef: moduleRef });
        break;
    }
  }

  /**
   * This is a workaround...
   * The native scrollPositionRestoration Angular router setting does not work because
   * we are using mat-sidenav-container as our main page element.
   * Angular does not scroll automatically to the top when changing route and
   * using this material component.
   *
   * This method also runs recaptcha v3 everytime the user changes route.
   */
  private _onNavigationEnd(): void {
    const matSideNavContentEl = this._document.querySelector('mat-sidenav-content');
    this._router.events
      .pipe(
        skip(1),
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this._handleHeaderAndFooterLoading();

        this._authenticationApi.checkLocalSession();

        // Some old browsers do not support scrollTo
        if (matSideNavContentEl.scrollTo) {
          matSideNavContentEl.scrollTo(0, 0);
        }

        this._recaptchaV3Service.execute(ROUTE_CHANGE);
      });
  }

  // NOTE: If we need to go back to setupOnLogout logic, see commit id: d202dc89cf6ee000d8cd491459f0a841ccb073b5
  private _setupOnLogout(): void {
    this._userApi.onLogout$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((loggedOut) => {
      // On logout, refresh the page
      if (loggedOut) {
        window.location.reload();
      }
    });
  }

  private _handleHeaderAndFooterLoading(): void {
    if (this._shouldShowHeaderAndFooter() && !this.headerAndFooterVisible) {
      this.headerAndFooterVisible = true;
      this.headerTag.nativeElement.removeAttribute('hidden');
      this._loadHeaderComponent().then();
      this._loadFooterComponent().then();
    }

    if (!this._shouldShowHeaderAndFooter() && this.headerAndFooterVisible) {
      this.headerAndFooterVisible = false;
      this.headerTag.nativeElement.setAttribute('hidden', 'true');
      this.headerContainer.clear();
      this.footerContainer.clear();
    }
  }

  private _shouldShowHeaderAndFooter(): boolean {
    let showHeaderAndFooter = true;
    ROUTES_WITHOUT_HEADER_FOOTER.forEach((route) => {
      showHeaderAndFooter = !route.test(this._document.location.pathname) && showHeaderAndFooter;
    });

    return showHeaderAndFooter;
  }

  private _openEnableMfaPromptDialog(): void {
    const currentUser = this._userApi.getUser();
    const hasPassword = currentUser?.has_password;
    const mfaConfigured = currentUser?.mfa_settings?.configured;
    const restrictToSingleIssuer = currentUser?.restrict_to_single_issuer;
    const dontShowMfaPrompt = this._cookies.get(DONT_SHOW_MFA_PROMPT);

    if (
      !currentUser ||
      !hasPassword ||
      mfaConfigured ||
      restrictToSingleIssuer ||
      dontShowMfaPrompt
    ) {
      return;
    }

    this._dialog
      .open(EnableMfaPromptDialogComponent, {
        width: '420px',
        panelClass: 'enable-mfa-prompt-dialog',
        data: {},
      })
      .afterClosed()
      .subscribe(({ dontShow, enable }: PromptDialogResult) => {
        if (dontShow) {
          this._cookies.set(DONT_SHOW_MFA_PROMPT, 'true');
        }

        if (enable) {
          window.open(this._accountsRedirection.getSecurityUrl(RECIPIENT_PORTAL), '_blank');
        }
      });
  }
}

const emitCustomHeaderFooterStatus = (componentStatus: object): void => {
  window.parent.postMessage(componentStatus, '*');
};
