import { EarningCriteria } from './earning-criteria.model';
import { AccredibleDesign } from './group.model';

export interface Organization {
  id: number;
  name: string;
  logo?: string;
}

export const EndorsementStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  EXPIRED: 'expired',
  WITHDRAWN: 'withdrawn',
} as const;

export type EndorsementStatusType = typeof EndorsementStatus[keyof typeof EndorsementStatus];

export interface CreditRecommendation {
  credits: number;
  credit_subject_area: string;
  credit_level: string;
  description: string;
}

export interface Competency {
  framework: string;
  statement: string;
}

export interface Group {
  id: number;
  name: string; // Identifier
  course_name: string; // Display name
  course_link: string;
  description: string;
  learning_outcomes: string[];
  earning_criteria: EarningCriteria[];
  certificate_design: AccredibleDesign;
  badge_design: AccredibleDesign;
  image_url?: string;
}

export interface Endorser {
  id: number;
  full_name?: string;
  name: string;
  description: string;
  logo?: string;
}

export interface Endorsement {
  id: string;
  organization: Organization;
  issuer_login: {
    id: number;
    email: string;
    name: string;
  };
  group: Group;
  status: EndorsementStatusType;
  version: string;
  created_at: string;
  updated_at: string;
  approved_at?: string;
  rejected_at?: string;
  rejection_reason?: string;
  endorser_course_id?: string;
  start_date?: string;
  end_date?: string;
  minimum_passing_percentage?: string;
  notes?: string;
  credit_transfer_id?: string;
  endorser_course_link?: string;
  credit_recommendations: CreditRecommendation[];
  competencies: Competency[];
  endorser_mark?: string;
  sibling_endorsements_count?: number;
  endorser: Endorser;
  endorser_name?: string;
}
