import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AccredibleBaseDialogComponent } from '../utils/base-dialog.component';
import { ConfirmDialogData } from './confirm-dialog.model';

const DEFAULT_CONFIRM_DIALOG_DATA = <ConfirmDialogData>{
  btnActionColor: 'primary',
};

@Component({
  templateUrl: './confirm-dialog.component.html',
})
export class AccredibleConfirmDialogComponent
  extends AccredibleBaseDialogComponent<AccredibleConfirmDialogComponent>
  implements OnInit
{
  constructor(
    @Inject(MAT_DIALOG_DATA) public confirmDialogData: ConfirmDialogData,
    public override readonly dialogRef: MatDialogRef<AccredibleConfirmDialogComponent>,
    protected override readonly _focusMonitor: FocusMonitor,
  ) {
    super(dialogRef, _focusMonitor);
  }

  ngOnInit(): void {
    this.confirmDialogData = {
      ...DEFAULT_CONFIRM_DIALOG_DATA,
      ...this.confirmDialogData,
    };
  }

  onBtnActionClick(): void {
    this.close(true);
  }
}
