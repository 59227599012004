import { FeatureFlags } from '@accredible-frontend-v2/models';
import { AccredibleApiService } from '@accredible-frontend-v2/services/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

const NO_ORGANIZATION_ID = 'no_org_id';

@Injectable({
  providedIn: 'root',
})
export class AccredibleRecipientFeatureFlagsService extends AccredibleApiService {
  private readonly _featureFlags$ = new Map<number | string, BehaviorSubject<FeatureFlags>>();

  featureFlags(organizationId?: number): FeatureFlags {
    const orgIdKey = organizationId || NO_ORGANIZATION_ID;
    return this._featureFlags$.get(orgIdKey)?.getValue() || null;
  }

  getFeatureFlags(organizationId?: number): Observable<FeatureFlags> {
    const orgIdKey = organizationId || NO_ORGANIZATION_ID;
    if (this._featureFlags$.get(orgIdKey)?.getValue()) {
      return this._featureFlags$.get(orgIdKey).asObservable();
    }

    this._featureFlags$.set(orgIdKey, new BehaviorSubject(null));

    return this._get(`/v1/recipient/features?organization_id=${organizationId}`).pipe(
      map((res) => this._handleResponse(res, 'features')),
      tap((res) => this._featureFlags$.get(orgIdKey).next(res)),
      catchError((res) => this._handleError(res, true)),
    );
  }
}
