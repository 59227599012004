export enum ActiveFeatureFlags {
  ACCREDIBLE_AVG = 'accredible_avg',
  PATHWAY_ENROLMENT = 'pathway_enrolment',
  PATHWAY_INFORMATION = 'pathway_information',
  PATHWAY_TRACKS = 'pathway_tracks',
  PRE_RENDER_GROUP_IMAGES = 'pre_render_group_images',
  PROFILE_COMPLETION_SIDEBAR = 'profile_completion_sidebar',
  SMARTRESUME_INTEGRATION = 'smartresume_integration',
  SMARTRESUME_INTEGRATION_SHARE_BUTTONS = 'smartresume_integration_share_buttons',
  SPOTLIGHT_BANNERS = 'spotlight_banners',
  V2_EMAIL_ANALYTICS = 'email_analytics_v2',
  EMAIL_EDITOR_GLOBAL_FONTS = 'email_editor_global_fonts',
  V2_EMAIL_EDITOR = 'v2_template_editor',
  ZAPIER_INTEGRATION = 'zapier_integration',
  INTEGRATION_CANVAS_SCHEDULED_AUTOISSUANCE = 'integration_canvas_scheduled_autoissuance',
  V2_TRANSCRIPTS = 'v2_transcripts',
  YOUTUBE_INSTAGRAM_TIKTOK_SHARE = 'youtube_instagram_tiktok_share',
  CREDENTIAL_ISSUER_ROLE = 'credential_issuer_role',
  ASK_RECOVERY_EMAIL = 'ask_recovery_email',
}

export type FeatureFlags = {
  [K in ActiveFeatureFlags]: boolean;
};
